<template>
  <div class="card">
    <Alerts ref="alert"></Alerts>
    <div class="card-header">
      <strong>Seleccionar un Estudiante</strong>
      <div class=" card-header-actions">
        <form class="form-inline">
          <b>Filas por p&aacute;gina</b>
          <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                  v-model="filasPorPagina">
            <option v-for="option in sizeoptions" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <button class="btn btn-outline-dark mr-2" type="button"
                  @click="borrarFiltroBtnOnClick()">
            <font-awesome-icon icon="fa-sort-amount-up-alt"/>
            Quitar Filtro
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-0 pe-1 pr-1">
      <JqxGrid :width="'100%'" ref="gridSystem"
               :source="dataAdapter"
               :autoheight="true" :autorowheight="false" :rowsheight="50"
               :pageable="true"
               :pagesize="parseInt(filasPorPagina)"
               :virtualmode="true"
               :rendergridrows="rendergridrows"
               :columns="columns" :enablebrowserselection="true"
               :enabletooltips="true" :filterable="true"
               :showfilterrow="true"
               :sortable="true"
               @rowclick="gridSystemOnRowSelect($event)"
               :pagermode="'simple'" :localization="localization"
               :theme="'bootstrap'" :columnsresize="true"/>
    </div>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Alerts from "@/views/notifications/Alerts";

var sourceLst;
var moment = require('moment');
var modalPdf;
var pagesizeoptions = [3, 6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'EditUser',
  components: {
    Alerts,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '9%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          filtertype: 'input',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '14%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '25%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Gestion', datafield: "gestion", width: '7%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Gestión de<br />Ingreso</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Gestion"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '5%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: '', datafield: "editar", width: '12%', cellsrenderer: function (row, column, value) {
            let html = '<button class="btn btn-success" type="button">Seleccionar</button>';
            return html;
          }, filterable: false, sortable: false
        },
        {text: '', datafield: "facultad_id", hidden: true},
        {text: '', datafield: "carrera_id", hidden: true},
        {text: '', datafield: "nacionalidad_id", hidden: true},
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    let url = this.$apiAdress + '/api/inscripcion?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada
    if(typeof this.$parent.carrerasArray !== 'undefined'){
      url += '&carreras='+this.$parent.carrerasArray;
    }
    sourceLst = {
      url: url,
      datafields: [
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'facultad_id', type: "int"},
        {name: 'carrera_id', type: "int"},
        {name: 'nacionalidad_id', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'apellido1',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    onRowclick: function (event) {
      let datos = this.$refs.gridSystem.getrowdata(event.args.rowindex);
      this.$emit('mostrarDatosAlumno',datos)
    },
    gridSystemOnRowSelect: function (event) {
      let datos = this.$refs.gridSystem.getrowdata(event.args.rowindex);
      this.$emit('mostrarDatosAlumno',datos)
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {
      }
    }
  },
  mounted: function () {
    modalPdf = this;
    console.log(this.$parent.carrerasArray)
  }
}
</script>