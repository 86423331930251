<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning" v-if="parametrosDocente.puede_editar">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <strong class="text-primary">
                  Designar materias
                </strong>
              </div>
<!--              <div class="col text-center" v-if="!mostrarGrilla">-->
<!--                <button class="btn btn-warning" @click="mostrarGrilla=true">-->
<!--                  Seleccionar otro estudiante-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="col text-right">-->
<!--                <button @click="$refs.modalVideo.mostrarVideo('video-alumno-regular-inscripción.mp4')"-->
<!--                        class="btn btn-outline-info" title="Ver video de ayuda">-->
<!--                  <font-awesome-icon icon="fas fa-question-circle" />-->
<!--                  Ayuda-->
<!--                </button>-->
<!--              </div>-->
            </div>
          </div>
          <div class="card-body p-2">
            <frmSeleccionarEstudiante ref="frmSeleccionarEstudiante" v-if="mostrarGrilla"
                                      @mostrarDatosAlumno="mostrarDatosAlumno"></frmSeleccionarEstudiante>

            <FrmDatosEstudiante v-if="inscripcion.codigo_alumno>0"
                ref="frmDatosEstudiante"
                                 :facultades="facultades" :carreras="carreras"
                                 :nacionalidades="nacionalidades" :inscripcion="inscripcion">
            </FrmDatosEstudiante>

            <template v-if="inscripcion.codigo_alumno>0">
              <div class="row">
                <div class="col"></div>
                <div class="col text-right "><h5 for="gestion" class="pt-2">Gestión:</h5></div>
                <div class="col">
                  <select v-model="inscripcion.gestion" class="form-control-plaintext h5" id="gestion" disabled>
                    <template v-for="(option,key) in gestiones">
                      <optgroup :label="key">
                        <option v-for="suboption in option" :value="suboption.value">
                          {{ suboption.label }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                </div>
                <div class="col"></div>
              </div>
              <hr/>
              <div class="row">
                <div v-for="(semestre, index) in materias" class="col-6">
                  <div class="card">
                    <div class="card-header"><strong>Semestre {{ index }}</strong>
                      <div class=" card-header-actions">
                        <CSelect
                            :options="grupos[index]"
                            :plain="true"
                            :value.sync="grupo_semestre[index]"
                            @change="actualizarGrupo(index)"
                            label=""
                        />
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table table-sm table-striped m-0">
                        <thead>
                        <tr>
                          <th width="6%"></th>
                          <th width="20%"><span>Sigla</span></th>
                          <th width="46%"><span>Materia</span></th>
                          <th width="34%"><span>Grupo</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(materia, index2) in semestre">
                          <td>
                            <input :value="materia.id"
                                   :checked="inscripcion.materias.hasOwnProperty(materia.id) && inscripcion.materias[materia.id]!==materiaPorDefecto"
                                   class="form-control" name="materia[]" type="checkbox"
                                   @change="deshabilitarMateria($event,materia.id)"
                            />
                          </td>
                          <td>{{ materia.sigla }}</td>
                          <td>{{ materia.materia }}</td>
                          <td>
                            <CSelect
                                :options="materia.grupos"
                                :plain="true"
                                :value.sync="inscripcion.materias[materia.id]"
                                @change="verificarMateria(materia.id)"
                                label=""
                            />
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="card-footer">
          <template :style="inscripcion.codigo_alumno>0?'':'display:none'">
            <button type="button" class="btn btn-primary mr-2" @click="store()">Grabar</button>
          </template>
            <button type="button" class="btn btn-danger" @click="goBack">Regresar a la pantalla anterior</button>
          </div>
        </div>
        <div class="card border-warning" v-if="!parametrosDocente.puede_editar">
          <div class="card-header">
            <strong class="text-primary">Designar materias</strong>
          </div>
          <div class="card card-body">
            <h6 style="text-align: justify">
              <strong class="text-danger">No</strong> es posible editar la designación de materias, contactese con el administrador del sistema
            </h6>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Table from "@/views/base/Table";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import frmSeleccionarEstudiante from "@/views/facturaenlinea/frmseleccionarestudiante.vue";
import FrmDatosEstudiante from "@/views/facturaenlinea/frmdatosestudiante";
import ModalVideo from "@/views/notifications/ModalVideo";

var sourceLst;
var moment = require('moment');
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];
var modalPdf;

export default {
  name: 'EditUser',
  components: {
    ModalVideo,
    Alerts,
    Toast,
    Table,
    FrmDatosEstudiante,
    frmSeleccionarEstudiante,
    JqxGrid,
    jqxInput
  },
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      mostrarGrilla: false,
      inscripcion: {
        id: 0,
        facultad_id: 0,
        carrera_id: 0,
        plan_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        nacionalidad_id: 0,
        sede_id: localStorage.sedeSeleccionada,
        materias: [],
      },
      parametrosDocente: {
        gestion:'',
        gestiones:[],
        grupo: 'designa_materias',
        cantidad_modificaciones: 2,
        fecha_ini: '',
        fecha_fin: '',
        puede_editar:false
      },
      limiteMateriasInscritas: 0,
      planes: [], materiaPorDefecto: ":: SELECCIONAR ::",
      materias: [], gestiones: [],
      grupos: [], grupo_semestre: [],
      facultades: [], carreras: [],
      nacionalidades: [], si_nos: [],carrerasArray:[],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
      sedeSeleccionada: localStorage.sedeSeleccionada,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    seleccionarMaterias(semestreIndex) {
      for (let semestre in modalPdf.materias[semestreIndex]) {
        modalPdf.inscripcion.materias.push(modalPdf.materias[semestreIndex][semestre].id);
      }
    },
    actualizaMaterias(codigo_alumno) {
      if (modalPdf.inscripcion.plan_id > 0 && modalPdf.inscripcion.carrera_id > 0) {
        axios.get(this.$apiAdress + '/api/inscripcion/' + modalPdf.inscripcion.plan_id + '/' + modalPdf.inscripcion.carrera_id + '/0/'+codigo_alumno+'/materias?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              modalPdf.limiteMateriasInscritas = response.data.limiteMateriasInscritas;
              modalPdf.materias = response.data.materias;
              modalPdf.grupos = response.data.grupos;
            })
            .catch(function (error) {
              console.log(error);
              modalPdf.$router.push({path: '/login'});
            });
      }
    },
    actualizarGrupo(semestre) {
      for (let pos in this.materias[semestre]) {
        this.inscripcion.materias[this.materias[semestre][pos].id] = this.grupo_semestre[semestre];
        if (this.grupo_semestre[semestre] === modalPdf.materiaPorDefecto) {
          delete this.inscripcion.materias[this.materias[semestre][pos].id];
        }
      }
    },
    verificarMateria(materia_id) {
      if (modalPdf.inscripcion.materias[materia_id] === modalPdf.materiaPorDefecto) {
        delete modalPdf.inscripcion.materias[materia_id];
      }
    },
    deshabilitarMateria(event, materia_id) {
      if (!event.target.checked) {
        modalPdf.inscripcion.materias[materia_id] = modalPdf.materiaPorDefecto;
        delete modalPdf.inscripcion.materias[materia_id];
      }
    },
    validarMaterias() {
      let errores = 0;
      let cuenta_materias = 0;
      for (let pos in modalPdf.inscripcion.materias) {
        if (modalPdf.inscripcion.materias[pos] === "" || modalPdf.inscripcion.materias[pos] === modalPdf.materiaPorDefecto) {
          errores++;
        }
        cuenta_materias++;
      }
      if (errores > 0) {
        modalPdf.$refs.mensajeToast.makeToast('', 'Debe seleccionar un grupo/paralelo para cada materia seleccionada', 'danger');
      }
      if (cuenta_materias > modalPdf.limiteMateriasInscritas) {
        modalPdf.$refs.mensajeToast.makeToast('', 'Se ha superado (' + cuenta_materias + ') el límite de materias permitidas (' + modalPdf.limiteMateriasInscritas + ')', 'danger');
        errores++;
      }
      return errores === 0;
    },
    store() {
      if (modalPdf.validarMaterias()) {
        modalPdf.$refs.alert.show("Grabando datos");
        modalPdf.inscripcion.id = modalPdf.$route.params.id;
        axios.post(this.$apiAdress + '/api/docentedesigmateria/storeantiguo?token=' + localStorage.getItem("api_token"),
            modalPdf.inscripcion
        )
            .then(function (response) {
              modalPdf.inscripcion = {
                id: 0,
                facultad_id: 0,
                carrera_id: 0,
                plan_id: 0,
                codigo_alumno: '',
                gestion: '',
                apellido1: '',
                apellido2: '',
                nombres: '',
                nacionalidad_id: 0,
                sede_id: localStorage.sedeSeleccionada,
                materias: [],
              };
              modalPdf.$refs.mensajeToast.makeToast('Info', 'Estudiante registrado/actualizado.', 'success');
              modalPdf.$refs.alert.hide();
              modalPdf.$router.push({path: '/docentedesigmateria/'});
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    mostrarDatosAlumno: function (datos) {
      this.mostrarGrilla = false
      modalPdf.mostrarDatosInscripcion(datos.codigo_alumno)
    },
    mostrarDatosInscripcion: function (codigo_alumno) {
      modalPdf.materias = [];
      axios.get(this.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/' + codigo_alumno + '/edit?token=' + localStorage.getItem("api_token")+
          '&tipo_lista=designa_materias')
          .then(function (response) {
            modalPdf.inscripcion = response.data.inscripcion;
            modalPdf.gestiones = response.data.gestiones;
            modalPdf.inscripcion.gestion = response.data.mesActual
            axios.get(modalPdf.$apiAdress + '/api/docentedesigmateria/' + localStorage.sedeSeleccionada + '/' + modalPdf.inscripcion.gestion + '/listarparametros?token=' + localStorage.getItem("api_token"))
                .then(function (response) {
                  modalPdf.parametrosDocente = response.data;
                  if(modalPdf.parametrosDocente.puede_editar) {
                    modalPdf.actualizaMaterias(codigo_alumno);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });

          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    reportePdf(factura_id) {
      let modalPdf = this;
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + factura_id + '/facturapdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
  },
  mounted: function () {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      modalPdf.sede = data;
    });
    axios.get(this.$apiAdress + '/api/docentedesigmateria/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.facultades = valorPorDefecto.concat(response.data.facultades);
          modalPdf.carreras = response.data.carreras;
          modalPdf.generos = valorPorDefecto.concat(response.data.generos);
          modalPdf.estados_civiles = valorPorDefecto.concat(response.data.estados_civiles);
          modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
          modalPdf.si_nos = valorPorDefecto.concat(response.data.si_nos);
          modalPdf.planes = valorPorDefecto.concat(response.data.planes);
          modalPdf.carrerasArray = response.data.carrerasArray;
          if (parseInt(modalPdf.$route.params.id) > 0 && parseInt(modalPdf.$route.params.codigo_alumno) > 0) {
            modalPdf.mostrarDatosInscripcion(modalPdf.$route.params.codigo_alumno);
          }else{
            modalPdf.mostrarGrilla=true
          }
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}
</script>
<style>
tr th.vertical-text {
  writing-mode: vertical-rl;
  text-align: left;
  min-width: 50px;
  transform: rotate(180deg);
  vertical-align: top;
}
.form-group {
  margin-bottom: 0 !important;
}
</style>